body {
  margin: 0;
  padding: 0;
  font-family: 'Crimson Text', serif;
  color: #333;
}

.app {
  font-family: 'Crimson Text', serif;
  color: #333;
  font-size: 14px;
  box-sizing: border-box;
  max-width: 1280px;
  margin: 0 auto;
}

.app-header img {
  max-width: 100%;
}

.app-content {
  font-size: large;
  flex-grow: 1;
}

.content-page {
  font-size: 20px;
  line-height: 1.7;
  margin-right: 10px;
}

.img-box {
  max-width: 30%;
  max-height: 100px;
  display: inline-block;
  margin: 10px;
}

.img-box img {
  width: 100%;
  height: 100%;
}

a, a:visited, a:link, a:hover {
  color: #8c63a1;
  text-decoration: none;
}

p, div {
  font-weight: 300;
  color: #333;
}

h1 {
  font-size: 14px;
  font-weight: 700;
  color: #333;
}

.app-layout {
    display: flex;
    flex-direction: column;
    padding: 5px 5vw 5px 5vw;
}

@media (min-width:961px) {
  .app-layout {
    padding: 30px 50px 30px 50px;
    flex-direction: row;
  }
}


@media (min-width:320px)  { /* smartphones, iPhone, portrait 480x320 phones */ }
@media (min-width:481px)  {
    h1 {
      font-size: 18px;
    }
 }
@media (min-width:641px)  { /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }
@media (min-width:961px)  {
  h1 {
    font-size: 22px;
  }
}
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ }
@media (min-width:1281px) { /* hi-res laptops and desktops */ }