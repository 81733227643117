
.insta {
    text-align: left;
    line-height: 0;
    width: 100%;
    flex-grow: 1;
    display: inline-block;
    box-sizing: border-box;
    margin-left: 0px;
}
.insta .follow-btn {
    width: 22px;
    height: 22px;
    line-height: 22px;
    display: inline-block;
    border-radius: 0;
    color: #8c63a1;
    text-align: center;
}

.insta .fa-spinner {
  font-size: 40px;
  color: #a88cb8;
}

/*
.insta-username {
  font-size: 15px;
  margin-left: 6px;
}

.insta-logo {
  border-radius: 50%;
  width:30px;
  height:30px;
  display: inline-block;
  vertical-align: middle;
  margin-top: -4px !important;
} */

.insta h1 {
  margin-bottom: 8px;
}
.insta-img {
  width: 100%;
  margin: 1% 0;
}

@media (min-width:481px)  {
  .insta-img {
    width: 48%;
    margin: 1%;
  }
  .insta .follow-btn {
    width: 26px;
    height: 26px;
    line-height: 26px;
  }
}

@media (min-width:961px) {
  .app-layout {
    padding: 30px 50px 30px 50px;
    flex-direction: row;
  }
  .insta {
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: 30px;
    background-color: transparent;
  }
  .insta-img {
    width: 30%;
    margin: 1.5%;
  }
  .insta .follow-btn {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
}
