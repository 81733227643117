.content-page.contact {
    margin-bottom: 80px;
  }

  form {
    max-width: 400px;
}

.contact h1 {
    margin-bottom: 25px;
    text-transform: uppercase;
    font-size: 22px;
    font-weight: 300;
}

label {
    display: block;
    margin-top: 10px;
    margin-bottom: 4px;
    font-weight: 300;
    font-size: 16px;
}

input, textarea {
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 2px;
    color: #333;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    display: block;
    float: none;
    font-size: 16px;
    border: 1px solid #ccc;
    padding: 6px 10px;
    height: 38px;
    width: 100%;
}

input[type=submit] {
    background-color: #8c63a1;
    border: none;
    color: white;
    padding: 14px 24px;
    height: auto;
    text-decoration: none;
    margin-top: 15px;
    cursor: pointer;
}

.error-message {
    color: #8c63a1;
    margin-top: 15px;
}