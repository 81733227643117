.portfolio {
    text-align: center;
    padding-top: 10px;
}
.portfolio > div {
    width: 50%;
    display: inline-block;
    padding: 0px 10px 10px 10px;
    box-sizing: border-box;
}
.portfolio > div img {
    max-width:100%;
}

@media (min-width:641px) {
    .portfolio > div {
        width: 33%;
        padding: 10px 20px 20px 20px;
    }
}