.gallery h1 {
    margin-bottom: 25px;
    text-transform: uppercase;
    font-size: 22px;
    font-weight: 300;
}
@media (min-width:961px) {
    .gallery h1 {
        margin-bottom: 40px;
    }
}