
.nav-bar {
    display: flex;
    flex-direction: column;
    background-color: white;
    color: #333;
    padding: 5px 0px;
}

.nav-bar .site-title h1 {
    font-size: 32px;
    margin-bottom: 0px;
    font-family: 'Amatic SC';
    display: block;
}
.nav-bar .site-title h2 {
    font-size: 12px;
    display: block;
    line-height: 18px;
}

.nav-bar li {
    display: inline-block;
    padding: 0 8px;
    list-style-type: none;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 300;
}

.nav-bar .nav-items > ul {
    /* margin-top: 0px; */
    /* margin-bottom: 5px; */
}

.nav-bar a, .nav-bar a:visited, .nav-bar a:link {
    color: #333;
    text-decoration: none;
    transition: 0.3s all;
}

.nav-bar a:hover {
    color: #8c63a1;
    text-decoration: none;
    transition: 0.3s all;
}

.nav-bar .site-title {
    flex-grow: 0;
    text-align: center;
}

.nav-bar h1 {
    margin: 0px;
    line-height: 30px;
    font-size: 26px;
    font-weight: 700;
}

.nav-bar h2 {
    margin: 0px;
    line-height: 28px;
    font-size: 26px;
    font-weight: 300;
}

.nav-bar .nav-items {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin-top: 16px;
    border-top: 1px solid #8c63a1;
    border-bottom: 1px solid #8c63a1;
}

.nav-bar .nav-items ul {
    padding-left: 0px;
}

.nav-bar .nav-items .fa {
    font-size: 14px;
    vertical-align: middle;
    margin-left: 3px;
}


.portfolio-dropdown-content {
    position: absolute;
    background-color: transparent;
    z-index: 1;
    padding-top: 5px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease;
}

.portfolio-dropdown-content .inner {
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.3);
    padding: 10px 0px 10px 0px;
    background: white;
    border-top: 2px solid #8c63a1;
    border-bottom: 2px solid #8c63a1;
}

.portfolio-dropdown-content li {
    padding-top: 4px;
    padding-bottom: 4px;
}

.portfolio-dropdown-content.show {
    opacity: 1;
    visibility: visible;
    transition: all 0.5s ease;
}

.portfolio-dropdown-btn:hover,
.portfolio-dropdown-content li a:hover,
.nav-bar .site-title h1:hover,
.nav-bar .nav-items .insta-nav-btn .fa:hover {
    color: #8c63a1;
}

.portfolio-dropdown-content li {
    display: block;
    margin: 0;
    text-align: left;
    z-index: 1;
    padding: 10px 20px 10px 20px;
}

.nav-bar .nav-items .insta-nav-btn .fa {
    font-size: 14px;
    color: #333;
    vertical-align: top;
    margin-top: -1px;
}

@media (min-width:481px) {
    .nav-bar {
        padding: 5px 20px;
    }
    .nav-bar li {
        padding: 0px 15px;
        font-size: 14px;
    }
    .nav-bar .nav-items .insta-nav-btn .fa {
        font-size: 16px;
        margin-top: 0px;
    }
    .portfolio-dropdown-content li {
        padding: 10px 20px 10px 20px;
    }
}


@media (min-width:961px) {
    /* .nav-bar {
        flex-direction: row;
        padding: 10px 50px;
    }
    .nav-bar .site-title h1 {
        font-size: 45px;
        margin-bottom: 8px;
        display: block;
    }
    .nav-bar .site-title h2 {
        font-size: 18px;
        display: block;
    }
    .nav-bar .nav-items {
        text-align: right;
        margin-top: 0px;
        border-top: none;
        border-bottom: none;
    } */
    .nav-bar .nav-items > ul {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .nav-bar li {
        padding: 0px 30px;
        font-size: 22px;
        font-weight: 300;
    }
    .nav-bar .inner li {
        font-size: 20px;
        padding-top: 8px;
        padding-bottom: 8px;
    }
    .portfolio-dropdown-content li {
        padding: 10px 20px 10px 20px;
    }
    .nav-bar .nav-items .insta-nav-btn .fa {
        font-size: 24px;
    }
}