.content-page.shop {
    min-height: 500px;
}

.shop h1 {
    margin-bottom: 25px;
    text-transform: uppercase;
    font-size: 22px;
    font-weight: 300;
}

.content-page.shop img {
    display: 'inline-block';
    max-width: 300px;
    margin-bottom: 15px;
}

.content-page.shop .tile-link {
    display: inline-block;
}

.image-links {
    text-align: center;
}

.image-links img {
    border: 1px solid #8c63a1;
}

.content-page.shop img {
    margin-left: 15px;
    margin-right: 15px;
}

@media (min-width:961px) {
    .image-links {
        text-align: left;
    }
    .content-page.shop .tile-link {
        display: inline;
    }
}

@media (min-width:1000px) {
    .content-page.shop a:first-child img {
        margin-left: 0px;
    }
}