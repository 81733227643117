.about .content {
    margin-right: 50px;
}

.col-sidebar {
    padding-top: 0.67em;
}

.about h1 {
    margin-bottom: 25px;
    text-transform: uppercase;
    font-size: 22px;
    font-weight: 300;
}