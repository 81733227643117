.content-area {
    display: flex;
    flex-direction: row;
}

.col-content {
    flex: 1;
}

.col-sidebar {
    flex: 0 1 200px;
}

.col-sidebar img {
    max-width: 200px;
}

.text-center {
    text-align: center;
}
